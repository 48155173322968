<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "奥云水众",
    meta: [
      {
        name: "Keywords",
        content: "奥云, 奥云水众,奥云水众传",
      },
      {
        name: "description",
        content: "奥云水众传媒官网",
      },
    ],
  },
  data() {
    return {};
  },
};
</script>

<style>
#app {
}
</style>

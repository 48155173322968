import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info';
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'

router.afterEach((to) => {
  if (typeof _hmt !== 'undefined') {
    _hmt.push(['_trackPageview', to.fullPath]);
  }
});

Vue.use(MetaInfo)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'index',
    meta: { title: '奥云水众传媒' }
  },
  {
    path: '/index',
    name: 'appIndex',
    component: () => import('../views/index/appIndex.vue'),
	meta: { title: '奥云水众传媒' }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/index.vue'),
  	meta: { title: '奥云水众传媒' }
  },
  {
    path: '/webs',
    name: 'webs',
    component: () => import('../views/webs/index.vue'),
  	meta: { title: '奥云水众传媒' }
  }
]

const router = new VueRouter({
	routes
})
console.log(router);

router.beforeEach((to, from, next) => {
	console.log(to);
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
export default router
